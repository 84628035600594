// Testimonial.js
import React from 'react';

const testimonials = [
  {
    id: 1,
    name: 'Dr. Rakesh Gupta',
    role: 'Pulmonologist',
    message:
      'The services provided are excellent! The website is intuitive and has helped me connect with patients efficiently.',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    name: 'Meera Soni',
    role: 'Gynecologist',
    message:
      "Fantastic platform! It's made managing appointments and consultations much easier for me.",
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 3,
    name: 'Suryansh Jain',
    role: 'Dermatologist',
    message:
      'A user-friendly platform with great features. My patients find it very convenient.',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 4,
    name: 'Suryansh Jain',
    role: 'Dermatologist',
    message:
      'A user-friendly platform with great features. My patients find it very convenient.',
    image: 'https://via.placeholder.com/150',
  },
];

const TestimonialCard = ({ name, role, message, image }) => {
  return (
    <div className="max-w-md bg-white shadow-lg rounded-lg p-6 mx-auto">
      <div className="flex items-center space-x-4">
        <img
          src={image}
          alt={name}
          className="w-16 h-16 rounded-full border-2 border-indigo-500"
        />
        <div>
          <h3 className="text-lg font-semibold">{name}</h3>
          <p className="text-sm text-gray-500">{role}</p>
        </div>
      </div>
      <p className="mt-4 text-gray-600 text-sm">{message}</p>
    </div>
  );
};

const Testimonial = () => {
  return (
    <div className="py-12 px-6 md:w-[100vw] w-full">
      <h2 className="text-3xl font-bold text-center text-white mb-8">
        Testimonials
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((testimonial) => (
          <TestimonialCard key={testimonial.id} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
