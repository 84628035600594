// ContactUs.js
import React from 'react';

const Contact = () => {
  return (
    <div className="bg-gray-100 py-12 px-6 md:w-[100vw] w-full">
      <h2 className="text-4xl font-bold text-center text-indigo-600 mb-8">
        Contact Us
      </h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Contact Form */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
            Get in Touch
          </h3>
          <form>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                className="mt-1 block w-full px-4 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full px-4 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Your Email"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="4"
                className="mt-1 block w-full px-4 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Your Message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Contact Details */}
        <div className="bg-white shadow-lg rounded-lg p-6 space-y-6">
          <h3 className="text-2xl font-semibold text-indigo-600">
            Contact Details
          </h3>
          <div>
            <h4 className="text-lg font-medium">Address</h4>
            <p className="text-gray-600">
              S-7, Sanchar Valley Building, Kanadia Main Rd, near BOI, Sanchar
              Nagar Ext, Sanchar Nagar Main, Indore, Madhya Pradesh 452016
            </p>
          </div>
          <div>
            <h4 className="text-lg font-medium">Phone</h4>
            <p className="text-gray-600">+91 88238 82929</p>
          </div>
          <div>
            <h4 className="text-lg font-medium">Email</h4>
            <p className="text-gray-600">docsearchhub@gmail.com</p>
          </div>
          <div>
            <h4 className="text-lg font-medium">Follow Us</h4>
            <div className="flex space-x-4 mt-2">
              <a
                href="#"
                className="text-gray-500 hover:text-indigo-600"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook text-2xl"></i>
              </a>
              <a
                href="#"
                className="text-gray-500 hover:text-indigo-600"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter text-2xl"></i>
              </a>
              <a
                href="#"
                className="text-gray-500 hover:text-indigo-600"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram text-2xl"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Map Section */}
      <div className="mt-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14720.600681757905!2d75.917173!3d22.7226591!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e32f001ef727%3A0xe4240451e4ec8017!2sR4%20Health%20Care%20-%20Marketing%20Agency%20For%20Doctors%7C%20Digital%20Marketing%20for%20doctors%7C!5e0!3m2!1sen!2sin!4v1732954133881!5m2!1sen!2sin"
          width="100%"
          height="300"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
