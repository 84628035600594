import React, { useEffect } from 'react';
import AppRoutes from './Routing/Routes';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      {/* Background Animation */}
      <div className="area z-0">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      {/* Main Content */}
      <AppRoutes />

      {/* Floating Contact Buttons */}
      <div className="fixed bottom-4 right-4 flex flex-col gap-2 z-50">
        <a
          href="https://wa.me/+91 8823882929"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-600 text-white p-3 rounded-full shadow-lg flex items-center justify-center hover:bg-green-700"
        >
          <FaWhatsapp className="text-2xl" />
        </a>
        <a
          href="tel:+91 8823882929"
          className="bg-blue-600 text-white p-3 rounded-full shadow-lg flex items-center justify-center hover:bg-blue-700"
        >
          <FaPhoneAlt className="text-2xl" />
        </a>
      </div>
    </div>
  );
}

export default App;
