import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaPhoneAlt } from 'react-icons/fa';
import Logo from '../assets/logo.png';

const departments = [
  { name: 'Cardiology', icon: '❤️', link: '/department/1' },
  { name: 'Neurology', icon: '🧠', link: '/department/2' },
  { name: 'Orthopaedics', icon: '🦴', link: '/department/3' },
  { name: 'Obs. & Gynecologist', icon: '🤰', link: '/department/4' },
  { name: 'Urologist', icon: '🚻', link: '/department/5' },
  { name: 'ENT', icon: '👂', link: '/department/6' },
  { name: 'Ophthalmology', icon: '👁️', link: '/department/7' },
  {
    name: 'Laparoscopic & General Surgery',
    icon: '🔪',
    link: '/department/8',
  },
  { name: 'Gastroenterology', icon: '🍴', link: '/department/9' },
  { name: 'Neuro Surgeon', icon: '🧠', link: '/department/10' },
  { name: 'Cardiac Surgeon', icon: '🧠', link: '/department/11' },
  { name: 'Oncologist', icon: '🎗️', link: '/department/12' },
  { name: 'Onco Surgeon', icon: '🎗️', link: '/department/13' },
  { name: 'Infertility', icon: '👶', link: '/department/14' },
  { name: 'Dermatology', icon: '🌿', link: '/department/15' },
  { name: 'Plastic Surgeon', icon: '✂️', link: '/department/16' },
  { name: 'Vascular Surgeon', icon: '🩸', link: '/department/17' },
  { name: 'Nephrologist', icon: '🩺', link: '/department/18' },
  { name: 'Interventional Radiologist', icon: '🔬', link: '/department/19' },
  {
    name: 'Dental & Maxillofacial Surgeon',
    icon: '🦷',
    link: '/department/20',
  },
  { name: 'Pediatric Gastroenterologist', icon: '🍼', link: '/department/21' },
  { name: 'Pediatric Surgeon', icon: '👶', link: '/department/22' },
  { name: 'Pulmonologist', icon: '🌬️', link: '/department/23' },
  { name: 'Gastro & Liver Surgeon', icon: '🍔', link: '/department/24' },
  { name: 'Pediatric Neurologist', icon: '🧠', link: '/department/5' },
  { name: 'Pathology', icon: '📊', link: '/department/26' },
  { name: 'Homeopathic', icon: '🌿', link: '/department/27' },
  { name: 'Ayurvedic', icon: '🍃', link: '/department/28' },
  { name: 'Hospital', icon: '🏥', link: '/department/29' },
  { name: 'Clinics', icon: '🏨', link: '/department/30' },
  { name: 'Psychiatric', icon: '🧠', link: '/department/1' },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDepartmentOpen, setIsDepartmentOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-customBlue z-50 fixed top-0 w-full">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <div>
          <Link to="/">
            <motion.img
              src={Logo}
              alt="Logo"
              className="object-contain md:w-16 w-10"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
            />
          </Link>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-6">
          <Link
            to="/"
            className={`text-lg ${
              isActive('/')
                ? 'bg-lighter text-white rounded-md px-3 py-2'
                : 'text-white hover:text-gray-300'
            }`}
          >
            Home
          </Link>

          <div
            className="relative text-white cursor-pointer z-50"
            onMouseEnter={() => setIsDepartmentOpen(true)}
            onMouseLeave={() => setIsDepartmentOpen(false)}
          >
            <span className="hover:text-gray-300 text-lg">Department</span>
            {isDepartmentOpen && (
              <div className="absolute overflow-y-auto h-96 mt-2 bg-white shadow-lg rounded-md">
                {departments.map((dept, index) => (
                  <Link
                    key={index}
                    to={dept.link}
                    className={`flex items-center space-x-2 px-3 py-2 hover:bg-gray-100 rounded-md transition-colors ${
                      isActive(dept.link) ? 'bg-lighter text-white' : ''
                    }`}
                  >
                    <span className="text-2xl">{dept.icon}</span>
                    <span className="text-gray-800 font-medium">
                      {dept.name}
                    </span>
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link
            to="/blog"
            className={`text-lg ${
              isActive('/blog')
                ? 'bg-lighter text-white rounded-md px-3 py-2'
                : 'text-white hover:text-gray-300'
            }`}
          >
            Blog
          </Link>
          <Link
            to="/contact"
            className={`text-lg ${
              isActive('/contact')
                ? 'bg-lighter text-white rounded-md px-3 py-2'
                : 'text-white hover:text-gray-300'
            }`}
          >
            Contact
          </Link>
        </div>

        {/* Call Now Button */}
        <div className="hidden md:block">
          <motion.a
            href="tel:+91 8823882929"
            className="bg-lighter text-white px-5 py-2 rounded-xl shadow-lg hover:bg-blue-700 flex items-center"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <FaPhoneAlt className="mr-2" /> 8823882929
          </motion.a>
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 right-0 w-64 h-full overflow-y-auto bg-customBlue z-40 transform transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <button
          className="absolute top-4 right-4 text-white text-2xl focus:outline-none"
          onClick={() => setIsOpen(false)}
        >
          &times;
        </button>
        <div className="px-4 py-4 space-y-4">
          <Link
            to="/"
            className="text-white block"
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          <div
            className="text-white block cursor-pointer"
            onClick={() => setIsDepartmentOpen(!isDepartmentOpen)}
          >
            Department
          </div>
          {isDepartmentOpen && (
            <div className="mt-2 overflow-y-auto h-60 bg-white rounded-md shadow-lg">
              {departments.map((dept, index) => (
                <Link
                  key={index}
                  to={dept.link}
                  className="block px-3 py-2 hover:bg-gray-100"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="text-2xl mr-2">{dept.icon}</span>
                  {dept.name}
                </Link>
              ))}
            </div>
          )}
          <Link
            to="/blog"
            className="text-white block"
            onClick={() => setIsOpen(false)}
          >
            Blog
          </Link>
          <Link
            to="/contact"
            className="text-white block"
            onClick={() => setIsOpen(false)}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
