import React from 'react';
import image1 from '../../assets/image-asian-woman-doctor-nurse-found-smth-looking-through-magnifying-glass-stare-surprised.jpg';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <div>
      {/* <section
        className=" h-screen  w-[100%] bg-cover bg-no-repeat bg-center z-0 md:bg-dekstopbanner bg-bannermobail"
        
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center ">
          <div className="container text-center">
      
          </div>
        </div>
      </section> */}

      {/* <!-- Hero Section --> */}
      <div className="bg-dekstopbanner bg-cover  h-[500px] mx-auto mt-10 flex flex-col md:flex-row items-center justify-center px-4 pt-12">
        {/* Left Text Section */}
        <div className="md:w-1/2 text-white text-center md:text-left py-96">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            <div className="flex md:flex-row flex-col gap-2">
              <div className="animate-bounceSearch  text-orange">Search ,</div>
              <div className="animate-bounceConnect text-blue-500">Connect</div>
              <div className="animate-bounceConnect">&</div>
              <div className="animate-bounceConsult text-green-500">
                Consult
              </div>
            </div>
            <br />
            with Top Medical Experts:
          </h1>

          <p className="text-lg font-medium mb-4">
            Your Gateway to Leading Healthcare Professionals
          </p>
          <p className="text-sm text-orange mb-8 font-bold">
            Platform for creatives around the world.
          </p>
          <div className="space-x-4">
            <Link
              to="/contact"
              className="bg-orange text-white py-2 px-4 rounded hover:bg-orange"
            >
              Get Quote Now
            </Link>
            <Link
              to="/contact"
              className="bg-transparent border border-white text-white py-2 px-4 rounded hover:bg-gray-200 hover:text-black"
            >
              Learn More
            </Link>
          </div>
        </div>

        {/* Right Image Section */}
        <div className="md:w-1/2 relative mt-10 md:mt-0">
          <div className="relative">
            {/* <img
              src={image1}
              alt="Medical Experts"
              className="rounded-lg shadow-lg"
            /> */}
            {/* Patient Recover Badge */}
            <div className="absolute md:block hidden top-4 left-4 bg-white px-4 py-2 rounded-full shadow-md text-gray-800 flex items-center space-x-2">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXD9idSNTkUN0RWcpa6D2Ge-MLjK0eZtmJDg&s"
                alt="Patients Icon"
                className="w-8 h-8 rounded-full"
              />
              <span>
                <strong>150K+</strong> Patient Recoveries
              </span>
            </div>
            {/* Doctors Badge */}
            <div className="absolute md:block hidden bottom-4 right-4 bg-white px-4 py-2 rounded-full shadow-md text-gray-800 flex items-center space-x-2">
              <img
                src="https://assets.codepen.io/3617690/hero-doctor.png"
                Docte-find
                alt="Doctors Icon"
                className="w-8 h-8 rounded-full"
              />
              <span>
                <strong>870+</strong> Doctors
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
