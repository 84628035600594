import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../assets/logo.png'; // Update with your logo path
const departments = [
  { name: 'Cardiology', icon: '❤️', link: '/department/1' },
  { name: 'Neurology', icon: '🧠', link: '/department/2' },
  { name: 'Orthopaedics', icon: '🦴', link: '/department/3' },
  { name: 'Obs. & Gynecologist', icon: '🤰', link: '/department/4' },
  { name: 'Urologist', icon: '🚻', link: '/department/5' },
  { name: 'ENT', icon: '👂', link: '/department/6' },
  { name: 'Ophthalmology', icon: '👁️', link: '/department/7' },
  {
    name: 'Laparoscopic & General Surgery',
    icon: '🔪',
    link: '/department/8',
  },
  { name: 'Gastroenterology', icon: '🍴', link: '/department/9' },
  { name: 'Neuro Surgeon', icon: '🧠', link: '/department/10' },
  { name: 'Cardiac Surgeon', icon: '🧠', link: '/department/11' },
  { name: 'Oncologist', icon: '🎗️', link: '/department/12' },
  { name: 'Onco Surgeon', icon: '🎗️', link: '/department/13' },
  { name: 'Infertility', icon: '👶', link: '/department/14' },
  { name: 'Dermatology', icon: '🌿', link: '/department/15' },
];
const dep = [
  { name: 'Plastic Surgeon', icon: '✂️', link: '/department/16' },
  { name: 'Vascular Surgeon', icon: '🩸', link: '/department/17' },
  { name: 'Nephrologist', icon: '🩺', link: '/department/18' },
  { name: 'Interventional Radiologist', icon: '🔬', link: '/department/19' },
  {
    name: 'Dental & Maxillofacial Surgeon',
    icon: '🦷',
    link: '/department/20',
  },
  { name: 'Pediatric Gastroenterologist', icon: '🍼', link: '/department/21' },
  { name: 'Pediatric Surgeon', icon: '👶', link: '/department/22' },
  { name: 'Pulmonologist', icon: '🌬️', link: '/department/23' },
  { name: 'Gastro & Liver Surgeon', icon: '🍔', link: '/department/24' },
  { name: 'Pediatric Neurologist', icon: '🧠', link: '/department/5' },
  { name: 'Pathology', icon: '📊', link: '/department/26' },
  { name: 'Homeopathic', icon: '🌿', link: '/department/27' },
  { name: 'Ayurvedic', icon: '🍃', link: '/department/28' },
  { name: 'Hospital', icon: '🏥', link: '/department/29' },
  { name: 'Clinics', icon: '🏨', link: '/department/30' },
  { name: 'Psychiatric', icon: '🧠', link: '/department/1' },
];

function Footer() {
  return (
    <footer className="bg-blue-900 py-12 text-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
          {/* Company Info */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Company Info</h4>
            <ul className="space-y-2">
              <li>
                {' '}
                <Link to="/">
                  <motion.img
                    src={Logo}
                    alt="Logo"
                    className="object-contain md:w-16 w-10"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3 }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/career" className="text-gray-300 hover:text-white">
                  Search. Connect. Consult.
                </Link>
              </li>
              <li>
                <Link to="/hiring" className="text-gray-300 hover:text-white">
                  Doc Search Hub simplifies your healthcare journey by
                  connecting you with qualified doctors and specialists. Find,
                  book, and consult effortlessly—all in one place.
                </Link>
              </li>
            </ul>
          </div>
          {/* Legal */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/home" className="text-gray-300 hover:text-white">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-300 hover:text-white">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-300 hover:text-white">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          {/* Departments */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Departments</h4>
            <ul className="space-y-2">
              {departments.map((dept, index) => (
                <li key={index}>
                  <Link
                    to={dept.link}
                    className="text-gray-300 hover:text-white"
                  >
                    {dept.icon} {dept.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* Resources */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Departments</h4>
            <ul className="space-y-2">
              {dep.map((dept, index) => (
                <li key={index}>
                  <Link
                    to={dept.link}
                    className="text-gray-300 hover:text-white"
                  >
                    {dept.icon} {dept.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* Get in Touch */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Get In Touch</h4>
            <ul className="space-y-2">
              <li className="flex items-center">
                <i className="fas fa-phone w-5"></i>
                <span className="ml-3">+91 8823882929</span>
              </li>
              <li className="flex items-center">
                <i className="fas fa-map-marker-alt"></i>
                <span className="ml-3">
                  S-7, Sanchar Valley Building, Kanadia Main Rd, near BOI,
                  Sanchar Nagar Ext, Sanchar Nagar Main, Indore, Madhya Pradesh
                  452016
                </span>
              </li>
              <li className="flex items-center">
                <i className="fas fa-envelope"></i>
                <span className="ml-3">docsearchhub@gamil.com</span>
              </li>
            </ul>
          </div>
        </div>
        {/* Bottom Footer */}
        <div className="border-t border-gray-400 mt-8 pt-4 flex justify-between items-center">
          <p className="text-gray-400">
            &copy; 2024 Doctor Info Listing Center. All rights reserved. @{' '}
            <a
              href="https://r4healthcare.in/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-white"
            >
              R4 Healthcare
            </a>
          </p>
          <div className="space-x-6">
            <a href="#" className="text-gray-400 hover:text-white">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
